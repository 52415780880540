<template>
    <div>
        <!--    列表 开始-->
        <div class="list" v-loading="listLoading">
            <div class="tabs" :style="{height: listHeight}" >
                <el-tabs v-model="data.tabs" @tab-click="tabPaneChange" tab-position="left">
                    <el-tab-pane name="全部" label="全部"></el-tab-pane>
                    <el-tab-pane 
                        v-for="(value,i) in floorList" 
                        :key="`${value}+${i}`" 
                        :name="value" 
                        :label="`F${value}`"
                    ></el-tab-pane>
                </el-tabs>
            </div>
            <div 
                class="cardList" 
                :style="{height: listHeight}" 
                @scroll="handleScroll" 
                id="cardlistdemo"
            >
                <el-card 
                    class="card" 
                    :style="{background: value.cardColor}" 
                    v-for="(value,i) in testdata" 
                    :key="i" 
                    shadow="hover"
                >
                    <el-button 
                        :type="value.buttonColor" 
                        v-if="value.diagramState == '空'" 
                        class="button" 
                        size="small"
                    >{{value.diagramState}}</el-button>
                    <el-button 
                        :type="value.buttonColor" 
                        v-else class="button" 
                        size="small"
                    >{{value.diagramState}}</el-button>
                    <div @click="roomDetailDblclick(value)" class="text">
                        <span 
                            class="room_number" 
                            :style="{color: value.roomColor}"
                        >{{value.diagramDoorplateno}}</span>
                        <div class="describe">
                            <span>{{value.diagramName}}</span><br>
                            <span>{{value.diagramDescribe}}</span><br>
                            <span>{{value.diagramRoomType}}</span><br>
                        </div>
                    </div>
                </el-card>
            </div>
        </div>
        <!--    列表 结束-->
        <el-dialog v-dialogDrag :visible.sync="control.dialog" v-loading="control.loading" :close-on-click-modal="false" width="1000px" class="equipmentWith">
            <el-tabs>
                <el-tab-pane label="设备集控">
                    <el-container>
                        <el-aside width="50%">
                            <el-row>
                                <el-col :span="12">
                                    <div class="topLeft">
                                        <p>开关控制</p>
                                        <div class="switch" ref="highlighted2"></div>
                                        <el-button class="controlButton" @click="socketPointOpen" ref="highlighted">全开</el-button>
                                        <el-button class="controlButton" @click="socketPointClose" ref="highlighted3">全关</el-button>
                                        <div class="visualization-setting__i" @click="settingClick(1)">
                                            <i class="el-icon-setting fz-24"></i>
                                        </div>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div class="topLeft">
                                        <!-- 2233 -->
                                        <p>灯光控制</p>
                                        <div class="switch" ref="highlightedLight2"></div>
                                        <el-button class="controlButton" @click="lightOpen" ref="highlightedLight">全开</el-button>
                                        <el-button class="controlButton" @click="lightCloes" ref="highlightedLight3">全关</el-button>
                                         <div class="visualization-setting__i" @click="settingClick(2)">
                                            <i class="el-icon-setting fz-24"></i>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <div class="topLeft">
                                        <p>窗帘控制</p>
                                        <div class="switch" ref="highlightedCurtain2"></div>
                                        <el-button class="controlButton" @click="curtainOpen" ref="highlightedCurtain">全开</el-button>
                                        <el-button class="controlButton" @click="curtainCloes" ref="highlightedCurtain3">全关</el-button>
                                         <div class="visualization-setting__i" @click="settingClick(3)">
                                            <i class="el-icon-setting fz-24"></i>
                                        </div>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div class="topLeft">
                                        <p>空调控制</p>
                                        <div class="switch" ref="highlightedAir2"></div>
                                        <el-button class="controlButton" @click="conditionerOpen" ref="highlightedAir">全开</el-button>
                                        <el-button class="controlButton" @click="conditionerCloes" ref="highlightedAir3">全关</el-button>
                                         <div class="visualization-setting__i" @click="settingClick(4)">
                                            <i class="el-icon-setting fz-24"></i>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-aside>
                        <el-main style="height: 520px">
                            <el-row>
                                <el-col :span="24">
                                    <el-row v-for="(page, index) of pages" :key="index">
                                        <transition-group name="fade">
                                            <el-col 
                                                :span="12" 
                                                v-for="item of page" 
                                                :key="item.jsId" 
                                                v-show="!pageShow || index === 0"
                                            >
                                                <div class="topRight" style="margin-top: 0;" @click="clickSceneType(item)">
                                                    <p>{{item.jscName}}</p>
                                                    <div class="switch" :ref="'sceneType' + item.jsId"></div>
                                                    <div style="height: 5px"></div>
                                                </div>
                                            </el-col>
                                        </transition-group>
                                    </el-row>
                                    <transition-group name="fade">
                                        <i 
                                            class="el-icon-caret-bottom" 
                                            @click="panelSwitch(false)"
                                            v-show="pageShow && sceneTypeList.length >= 2"
                                            key="1"
                                        ></i>
                                        <i 
                                            class="el-icon-caret-top" 
                                            @click="panelSwitch(true)"
                                            v-show="!pageShow && sceneTypeList.length >= 2"
                                            key="2"
                                        ></i>
                                    </transition-group>
                                </el-col>
                            </el-row>
                            <el-row v-show="pageShow">
                                <el-col :span="12" style="text-align: center">
                                    <el-progress :percentage="percentage3" :text-inside="true" :stroke-width="40" class="progressBar"></el-progress>
                                    <div class="progressButton">
                                        <el-button icon="el-icon-minus" @click="decrease3" circle></el-button>
                                        <span>亮度</span>
                                        <el-button icon="el-icon-plus" @click="increase3" circle></el-button>
                                    </div>
                                </el-col>
                                <el-col :span="12" style="text-align: center">
                                    <el-progress :percentage="percentage4" :text-inside="true" :stroke-width="40" class="progressBar"></el-progress>
                                    <div class="progressButton">
                                        <el-button icon="el-icon-minus" @click="decrease4" circle></el-button>
                                        <span>色温</span>
                                        <el-button icon="el-icon-plus" @click="increase4" circle></el-button>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row v-show="pageShow">
                                <el-col :span="12" style="text-align: center">
                                    <el-progress :percentage="percentage" :format="unit" :text-inside="true" :stroke-width="40" class="progressBar"></el-progress>
                                    <div class="progressButton">
                                        <el-button icon="el-icon-minus" @click="decrease" circle></el-button>
                                        <span>风速</span>
                                        <el-button icon="el-icon-plus" @click="increase" circle></el-button>
                                    </div>
                                </el-col>
                                <el-col :span="12" style="text-align: center">
                                    <el-progress :percentage="percentage2" :format="unit2" :text-inside="true" :stroke-width="40" class="progressBar"></el-progress>
                                    <div class="progressButton">
                                        <el-button icon="el-icon-minus" @click="decrease2" circle></el-button>
                                        <span>温度</span>
                                        <el-button icon="el-icon-plus" @click="increase2" circle></el-button>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row style="background-color: #E4E7ED;" v-show="pageShow">
                                <div class="model">
                                    <div class="modelChild">
                                        <p>制冷</p>
                                        <div class="bigDot" @click="pointRefrigeration" ref="pointRefrigeration">
                                            <el-image :src="img.url1" fit="fill"></el-image>
                                        </div>
                                    </div>
                                    <div class="modelChild">
                                        <p>制热</p>
                                        <div class="bigDot" @click="pointHeating" ref="pointHeating">
                                            <el-image :src="img.url2" fit="fill"></el-image>
                                        </div>
                                    </div>
                                    <div class="modelChild">
                                        <p>自动</p>
                                        <div class="bigDot" @click="pointAutomatic" ref="pointAutomatic">
                                            <el-image :src="img.url3" fit="fill"></el-image>
                                        </div>
                                    </div>
                                    <div class="modelChild">
                                        <p>除湿</p>
                                        <div class="bigDot" @click="pointArefaction" ref="pointArefaction">
                                            <el-image :src="img.url4" fit="fill"></el-image>
                                        </div>
                                    </div>
                                    <div class="modelChild">
                                        <p>送风</p>
                                        <div class="bigDot" @click="pointAeration" ref="pointAeration">
                                            <el-image :src="img.url5" fit="fill"></el-image>
                                        </div>
                                    </div>
                                </div>
                            </el-row>
                        </el-main>
                    </el-container>
                </el-tab-pane>
                <el-tab-pane label="设备单控">
                    <EquipmentTable
                        ref="equipmentTable"
                        :form="form"
                        @update="getVisualization"
                    >
                        <el-row v-for="(page, index) of devicePages" :key="index" class="single_control">
                            <el-col :span="6" v-for="(item) of page" :key="item.jourDeviceId">
                                <div 
                                    class="topLeft" 
                                    style="margin-top: 0;" 
                                    @click="caoZuoClick(item)"
                                >
                                    <img :src="item.dftIcon">
                                    <div>{{item.devNickname}}</div>
                                </div>
                            </el-col>
                        </el-row>
                    </EquipmentTable>
                </el-tab-pane>
            </el-tabs>
            
        </el-dialog>
        <el-dialog
          :title="locationTitle"
          :visible.sync="locationDialog"
          :close-on-click-modal="false"
          width="900px"
        >
          <el-row class="transferStyle"
            ><!--穿梭框-->
            <el-col>
              <el-transfer
                v-model="selectLocation"
                :props="{ key: 'id', label: 'value' }"
                :data="collectiveList"
              ></el-transfer>
            </el-col>
          </el-row>
          <div slot="footer">
            <el-button @click="locationDialog = false">取 消</el-button>
            <el-button type="primary" @click="locationDialogSave"
              >保 存</el-button
            >
          </div>
        </el-dialog>
        <SettingDialog 
        ref="settingDialog" :settingId="settingId" 
        :dialogVisible="settingdialogVisible" 
        @handleSettingDialogClose="handleSettingDialogClose"/>
         <!-- <el-dialog v-dialogDrag width="1000px" :title="settingTitle" :visible.sync="settingdialogVisible" :before-close="handleSettingDialogClose">
            <PairTable ref="pairTable" dataText="已配对" :deviceId="settingId"/>
        </el-dialog> -->
    </div>
</template>
<script>
import EquipmentTable from '@/components/EquipmentTable'
import { sceneModeControl } from '@/api/scene';
// import PairTable from '@/components/EquipmentTable/components/pairTable'
import { addHouseDevice } from '../../api/visualization'
import SettingDialog from './visualization-cpns/settingDialog'
let timer
export default {
    name: 'visualization',
    components: {
      EquipmentTable,
    //   PairTable,
      SettingDialog
    },
    data(){
        return{
            settingdialogVisible:false,
            listLoading:false,
            settingTitle:"",
            testdata:[],
            data: {//数据分页查询
                params: {
                    hsAddCommunity: '',//门店名称
                    hsRoomType: '',//客房类型
                    hsAddDoorplateno: '',//房号
                    hsAddFloor: '',//楼层
                    screenType: '所有客房',//筛选按钮
                },
                sortBtnNum: {
                    allNum: 0,
                    vacantCleanNum: 0,
                    vacantCleaningNum: 0,
                    vacantRepairNum: 0,
                    todayArriveNum: 0,
                    todayLeaveNum: 0,
                    todayReserveNum: 0,
                    arrearsNum: 0,
                    reserveNum: 0,
                    retainNum: 0,
                    ordinaryNum: 0,
                    hourNum: 0,
                },
                tabs: '全部',
            },
            floorList: [],
            listHeight: `${window.innerHeight - 103}px`,
            singledata:0, //计算第一次房态数据
            control: {
                dialog: false,
                hsId: '',
                loading: false,
            },
            selectLocationInput: '',
            percentage: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
            img: {
                url1: require('@/assets/icon-1.png'),
                url2: require('@/assets/icon-2.png'),
                url3: require('@/assets/icon-3.png'),
                url4: require('@/assets/icon-4.png'),
                url5: require('@/assets/icon-5.png'),
            },
            collective: {
                devState: '', //开关
                devType: '', //设备类型
                devAirPattern: '制冷', //空调模式
                devAirTemperature: 26, //空调温度
                devAirWindSpeed: '中', //空调风速
                controlState: [], //集控设备状态
            },
            deviceInfoData: [], //设备详情数组
            selectLocation: [], //选择集控位置设备
            conditionerState: '', //空调开关状态
            locationTitle: '', //位置添加标题
            locationDialog: false,
            //集控
            collectiveList: [], //集控设备列表
            lamplightState: '', //灯光开关状态
            form: { //设备列表数据
                sortGp: {//正倒序
                    ascBtn: 'primary',
                    descBtn: '',
                },
                params: {
                    deviceName: '',
                    deviceSN: '',
                    idftName: '',
                    hsAddCommunity: '',
                    devFirstType: '',
                    devSecondType: '',
                    orderRule: 2, //排序规则
                    orderWord: 5 //1楼盘 2楼栋 3门牌号 4设备类型
                    //jhcName: ''
                },
                dataList: [],
                selIdpId: '',
                clickrow: '',
                selrows: '',
                loading: false,
                cursor: 1, //当前页面
                limit: 10, //每页个数
                total: 0, //总条数
                pages: 0, //总页数
            },
            sceneTypeList: [],
            pageShow: false,
            dataList: [],
            // 
            settingFlag:null,
            settingdialogVisible:false,
            // 需要设置的房间id 初始设置
            settingId:999999999999999,
            deviceId:'',
            idMuster:[]
        }
    },
    computed: {
        pages() {
            const pages = []
            this.sceneTypeList.forEach((item, index) => {
                const page = Math.floor(index / 2)//2代表2条为一行，随意更改
                if (!pages[page]) {
                    pages[page] = []
                }
                pages[page].push(item)
            })
            return pages
        },
        devicePages() {
            const pages = []
            this.form.dataList.forEach((item, index) => {
                const page = Math.floor(index / 4)//4代表4条为一行，随意更改
                if (!pages[page]) {
                    pages[page] = []
                }
                pages[page].push(item)
            })
            return pages
        }
    },
    created(){
        this.getDataList()
        this.getDoorCardState()
    },
    mounted(){
        window.onresize = () => {
            return (() => {
                this.listHeight = `${window.innerHeight - 103}px`
            })()
        }
        this.cardlistdemo()
    },
    methods: {
        // 控制灯光
        handleControlLamp(){
            console.log(this.percentage4,this.percentage3);
            const flag = 2
            const data = {
                devState:this.collective.devState,
                jcrType:flag,
                jdcHsId:this.settingId,
                devBrightness:this.percentage3,
                devId:1,
                devColorTemperature:this.percentage4
            }
            // return 
            console.log(data);
            this.$axios.post('/common2/centrolRelation/operate',data).then(res => {
                console.log(res);
            })
        },
        // 开关控制
        handleSwitch(){
            const flag = 1
            const data = {
                devState:this.collective.devState,
                jcrType:flag,
                devId:1,
                jdcHsId:this.settingId
            }
            console.log(data)
            this.$axios.post('/common2/centrolRelation/operate',data).then(res => {
                console.log(res);
            })
        },
        // 窗帘控制
        handleCurtains(){
            const flag = 3
            const data = {
                devState:this.collective.devState,
                jcrType:flag,
                devId:1,
                jdcHsId:this.settingId
                // devPercentage:'' //暂无
            }
            console.log(data)
            // return
            this.$axios.post('/common2/centrolRelation/operate',data).then(res => {
                console.log(res);
            })
        },
        // 空调控制
        handleAirConditioner(){
            const flag = 4
            const data = {
                devState:this.collective.devState,
                jcrType:flag,
                devId:1,
                jdcHsId:this.settingId,
                // 空调温度
                devAirTemperature:this.collective.devAirTemperature,
                devAirPattern:this.collective.devAirPattern,
                devAirWindSpeed:this.collective.devAirWindSpeed,
                // devAirSweepingWind:'' 暂无
            }
            console.log(data)
            return
             this.$axios.post('/common2/centrolRelation/operate',data).then(res => {
                console.log(res);
            })
        },
        handleSettingDialogClose(){
            this.settingdialogVisible = false
        },
        settingClick(flag){
            this.settingdialogVisible = true
            //   switch(flag){
            //     case 1:
            //         this.settingTitle = "开关控制"
            //         break
            //     case 2:
            //         this.settingTitle = "灯光控制"
            //         break
            //     case 3:
            //         this.settingTitle = "窗帘控制"
            //         break
            //     case 4:
            //         this.settingTitle = "空调控制"
            //         break
            // }
            // this.deviceId = this.idMuster[flag-1].jcrDevId
            // this.$nextTick(() => {
            //     this.$refs['pairTable'].paired()
            // })
            // 触发 设置弹窗 获取数据的函数 flag 集控类型 
            this.$refs['settingDialog'].getSettingInfo(flag,this.settingId)
        },
        cardlistdemo(){
            let dom= document.getElementById("cardlistdemo");
            let divWidth =dom.offsetWidth;
            let divHeight = dom.offsetHeight;
            let a =Math.ceil(divWidth/120)*Math.ceil(divHeight/120)
            this.singledata=a
        },
        handleScroll(event){
            if(this.singledata>this.dataList.length){
                return
            }
            const scrollDistance =
                // 正文全文高
                event.target.scrollHeight -
                // 被卷去的高
                event.target.scrollTop -
                // 可见区域的宽度
                event.target.clientHeight;
            // 滚动条距离底部小于等于0证明已经到底了
            if (scrollDistance <= 1 ) {  
                if(this.testdata.length==this.dataList.length){
                    return
                }
                let a =this.dataList.slice(this.singledata)
                this.testdata=this.testdata.concat(a)
            }      
        },
        //查询数据
        getDataList(){//查询数据
            console.log(this.data.params.screenType)
            this.listLoading = true
            this.$axios({
                method: 'get',
                url: '/hotel/house4store/getHouseDiagram',
                params: this.data.params
            }).then(res => {
                if (res.data.result.length > 0) {
                    this.dataList = res.data.result
                    this.testdata = res.data.result.slice(1,this.singledata+1)
                    for (let i of this.dataList) {
                        i.roomColor = '#EBEEF5'
                        if (i.diagramState == '空') {
                            i.cardColor = '#1991f0'
                            i.buttonColor = 'primary'
                        } else if (i.diagramState == '住') {
                            i.cardColor = '#24a854'
                            i.buttonColor = 'success'
                        } else if (i.diagramState == '欠') {
                            i.cardColor = '#24a854'
                            i.buttonColor = 'danger'
                        } else if (i.diagramState == '预' || i.diagramState == '留') {
                            i.cardColor = '#24a854'
                            i.buttonColor = 'warning'
                        } else if (i.diagramState == '脏' || i.diagramState == '维' || i.diagramState == '修') {
                            i.cardColor = '#6d6d7f'
                            i.buttonColor = 'info'
                        }
                    }
                    this.data.sortBtnNum = {
                        allNum: this.dataList[0].totalNum,
                        vacantCleanNum: this.dataList[0].vacantCleanNum,
                        vacantCleaningNum: this.dataList[0].vacantCleaningNum,
                        vacantRepairNum: this.dataList[0].vacantRepairNum,
                        todayArriveNum: this.dataList[0].todayArriveNum,
                        todayLeaveNum: this.dataList[0].todayLeaveNum,
                        todayReserveNum: this.dataList[0].todayReserveNum,
                        arrearsNum: this.dataList[0].arrearsNum,
                        reserveNum: this.dataList[0].reserveNum,
                        retainNum: this.dataList[0].retainNum,
                        ordinaryNum: this.dataList[0].ordinaryNum,
                        hourNum: this.dataList[0].hourNum,
                    }
                } else {
                    this.dataList = []
                    this.data.sortBtnNum = {
                        allNum: 0,
                        vacantCleanNum: 0,
                        vacantCleaningNum: 0,
                        vacantRepairNum: 0,
                        todayArriveNum: 0,
                        todayLeaveNum: 0,
                        todayReserveNum: 0,
                        arrearsNum: 0,
                        reserveNum: 0,
                        retainNum: 0,
                        ordinaryNum: 0,
                        hourNum: 0,
                    }
                }
                let arr = []
                for (let key in this.dataList) {
                    if (key > 0) {
                        this.dataList[key].hsId = this.dataList[key].diagramHsId
                        arr.push(this.dataList[key])
                    }
                }
                console.log(this.data.params.screenType)
                this.dataList = arr
                this.floorList = res.data.result[0].floorList
                this.listLoading = false
            }).catch(err => {
                this.dataList = []
                this.isError(err,this)
                this.listLoading = false
            })
        },
        getDoorCardState(){//门牌号变色
            this.$axios({
                method: 'get',
                url: '/hotel/house4store/getCardPanel',
            }).then(res => {
                let data=res.data.result
                console.log(this.dataList)
                if (data.length > 0) {
                    for (let i of this.dataList) {
                        for (let k of res.data.result) {
                            if (i.diagramHsId == k.hsId) {
                                if ( k.devState == '插卡') {
                                    i.roomColor = '#303133'
                                } else {
                                    i.roomColor = '#EBEEF5'
                                }
                            }
                        }
                    }
                }
            }).catch(err => {
                this.isError(err,this)
                // console.log(err)
            })
        },
        tabPaneChange(){//点击楼层
            this.data.params.hsAddFloor = this.data.tabs == '全部' ? '' : this.data.tabs
            this.getDataList()
        },
        // 2233
        roomDetailDblclick(val) {
            console.log(val)
            // 保存hsid
            this.settingId = val.hsId
            this.control.hsId = val.diagramHsId
            this.control.dialog = true
            this.getAllSceneType()
            this.getVisualization()
            addHouseDevice(val.hsId).then(res => {
                // console.log(res,'674');
                this.idMuster = res.data.result || []
            })
        },
        //集控全开全关
        socketPointOpen() {
            //插座开
            this.collective.devState = '开';
            this.collective.devType = '开关';
            this.collectiveSave();
            // this.handleSwitch()
            this.$refs.highlighted.$el.style.backgroundImage =
            'linear-gradient(to right, #51b44d , #6ac7ea)';
            this.$refs.highlighted2.style.backgroundImage =
            'linear-gradient(to right, #51b44d , #6ac7ea)';
            this.$refs.highlighted3.$el.style.backgroundImage =
            'linear-gradient(to right, #cccccc , #cccccc)';
        },
        //插座关
        socketPointClose() {
            this.collective.devState = '关';
            this.collective.devType = '开关';
            this.collectiveSave();
            // this.handleSwitch()
            this.$refs.highlighted.$el.style.backgroundImage =
            'linear-gradient(to right, #cccccc , #cccccc)';
            this.$refs.highlighted2.style.backgroundImage =
            'linear-gradient(to right, #6ac7ea , #6ac7ea)';
            this.$refs.highlighted3.$el.style.backgroundImage =
            'linear-gradient(to right, #51b44d , #6ac7ea)';
        },
        // 2233
        async collectiveSave() {
            this.deviceInfoData = await this.collectiveSaveRequest(); //获取设备列表
            if (this.deviceInfoData.length == 0) {
                this.$message.warning('没有设备');
                return;
            }
            this.collectiveRequest(); //批量控制设备
        },
        //获取设备列表
        collectiveSaveRequest() {
            return this.$axios({
                method: 'get',
                url: '/common2/devices/list/operate',
                params: {
                    hsIds: this.control.hsId,
                    devType: this.collective.devType,
                },
            })
                .then((res) => {
                    for (let i of res.data.result) {
                        let a =
                        i.hsAddCommunity == null || i.hsAddCommunity == undefined
                            ? ''
                            : `${i.hsAddCommunity}\u3000`;
                        let b =
                        i.hsAddBuilding == null || i.hsAddBuilding == undefined
                            ? ''
                            : `${i.hsAddBuilding}\u3000`;
                        let c =
                        i.hsAddDoorplateno == null || i.hsAddDoorplateno == undefined
                            ? ''
                            : `${i.hsAddDoorplateno}`;
                        i.hsAddCommunity = a + b + c;
                    }
                    return res.data.result;
                })
                .catch((err) => {
                    this.isError(err, this);
                });
        },
        //批量控制设备请求
        async collectiveRequest() {
            let ids = [];
            for (let i of this.deviceInfoData) {
                if (i.result == undefined) {
                    this.$set(i, 'result', '');
                }
                // return //4.27 2202
                ids = await this.batchControl(i, ids);
            }
            //获取设备状态
            if (ids.length == 0) return;
            // this.$axios({
            //     method: 'get',
            //     url: `/common2/devices/getBatchState`,
            //     params: {
            //         ids: ids.join(','),
            //     },
            // })
            //     .then((res) => {
            //         this.collective.controlState = res.data.result;
            //         for (let i of this.deviceInfoData) {
            //             for (let j of this.collective.controlState) {
            //                 if (i.jourDeviceId == j.id) {
            //                     this.$set(i, 'State', '');
            //                     i.State = j.stateInformation;
            //                 }
            //             }
            //         }
            //     })
            //     .catch((err) => {
            //         this.isError(err, this);
            //     });
            this.deviceInfo = true;
        },
        batchControl(data, ids) {
            return //4 27 22:27
            return this.$axios({
                method: 'post',
                url: '/common2/devices/operate',
                data: {
                    devId: data.jourDeviceId,
                    devNumber: data.devRoad, //路数
                    devState: this.collective.devState, //开关
                    devBrightness: this.percentage3, //亮度
                    devColorTemperature: this.percentage4, //色温
                    devAirTemperature: this.collective.devAirTemperature, //空调温度
                    devAirWindSpeed: this.collective.devAirWindSpeed, //空调风速
                    devAirPattern: this.collective.devAirPattern, //空调模式
                },
            })
                .then((res) => {
                    data.result = '成功';
                    ids.push(data.jourDeviceId);
                    return ids;
                })
                .catch((err) => {
                    data.result = '失败';
                    // this.keyData++
                    this.isError(err, this);
                    return ids;
                });
        },
        lightOpen() {
            this.collective.devState = '开';
            this.collective.devType = '灯光';
            this.lamplightState = '开';
            //灯光开启默认50
            this.percentage3 = 50;
            this.percentage4 = 50;
            this.collectiveSave();
            // this.handleControlLamp();
            //灯光样式
            this.$refs.highlightedLight.$el.style.backgroundImage =
            'linear-gradient(to right, #51b44d , #6ac7ea)';
            this.$refs.highlightedLight2.style.backgroundImage =
            'linear-gradient(to right, #51b44d , #6ac7ea)';
            this.$refs.highlightedLight3.$el.style.backgroundImage =
            'linear-gradient(to right, #cccccc , #cccccc)';
        },
        lightCloes() {
            this.collective.devState = '关';
            this.collective.devType = '灯光';
            
            this.lamplightState = '关';
            //灯光关闭默认0
            this.percentage3 = 0;
            this.percentage4 = 0;
            this.collectiveSave();
            // this.handleControlLamp()
            //灯光样式
            this.$refs.highlightedLight.$el.style.backgroundImage =
            'linear-gradient(to right, #cccccc , #cccccc)';
            this.$refs.highlightedLight2.style.backgroundImage =
            'linear-gradient(to right, #6ac7ea , #6ac7ea)';
            this.$refs.highlightedLight3.$el.style.backgroundImage =
            'linear-gradient(to right, #51b44d , #6ac7ea)';
        },
        curtainOpen() {
            this.collective.devState = '开';
            this.collective.devType = '窗帘';
            this.collectiveSave();
            // this.handleCurtains()
            //窗帘样式
            this.$refs.highlightedCurtain.$el.style.backgroundImage =
            'linear-gradient(to right, #51b44d , #6ac7ea)';
            this.$refs.highlightedCurtain2.style.backgroundImage =
            'linear-gradient(to right, #51b44d , #6ac7ea)';
            this.$refs.highlightedCurtain3.$el.style.backgroundImage =
            'linear-gradient(to right, #cccccc , #cccccc)';
        },
        curtainCloes() {
            this.collective.devState = '关';
            this.collective.devType = '窗帘';
            this.collectiveSave();
            // this.handleCurtains()
            //窗帘样式
            this.$refs.highlightedCurtain.$el.style.backgroundImage =
            'linear-gradient(to right, #cccccc , #cccccc)';
            this.$refs.highlightedCurtain2.style.backgroundImage =
            'linear-gradient(to right, #6ac7ea , #6ac7ea)';
            this.$refs.highlightedCurtain3.$el.style.backgroundImage =
            'linear-gradient(to right, #51b44d , #6ac7ea)';
        },
        conditionerOpen() {
            this.collective.devState = '开';
            this.collective.devType = '空调';
            this.conditionerState = '开';
            //默认打开制冷
            this.$refs.pointRefrigeration.style.backgroundColor = '#53b552';
            this.percentage = 66.666;
            this.percentage2 = 71.0;
            // this.percentage = 0;
            // this.percentage2 = 0;
            // this.percentage2 = 0;
            this.$nextTick(() => {
                this.collectiveSave();
                // this.handleAirConditioner()
            })
            //空调打开样式
            this.$refs.highlightedAir.$el.style.backgroundImage =
            'linear-gradient(to right, #51b44d , #6ac7ea)';
            this.$refs.highlightedAir2.style.backgroundImage =
            'linear-gradient(to right, #51b44d , #6ac7ea)';
            this.$refs.highlightedAir3.$el.style.backgroundImage =
            'linear-gradient(to right, #cccccc , #cccccc)';
        },
        conditionerCloes() {
            this.collective.devState = '关';
            this.collective.devType = '空调';
            this.collectiveSave();
            // this.handleAirConditioner()
            this.conditionerState = '关';
            //关闭空调默认
            this.percentage = 0;
            this.percentage2 = 0;
            this.defaultState();
            //空调关闭样式
            this.$refs.highlightedAir.$el.style.backgroundImage =
            'linear-gradient(to right, #cccccc , #cccccc)';
            this.$refs.highlightedAir2.style.backgroundImage =
            'linear-gradient(to right, #6ac7ea , #6ac7ea)';
            this.$refs.highlightedAir3.$el.style.backgroundImage =
            'linear-gradient(to right, #51b44d , #6ac7ea)';
        },
        //亮度加
        increase3() {
            if (
                this.lamplightState == '关' ||
                this.lamplightState == '' ||
                this.lamplightState == undefined
            ) {
                this.$message.warning('请打开灯光开关');
            } else if (this.lamplightState == '开') {
                this.percentage3 += 10;
                if (this.percentage3 > 100) {
                    this.percentage3 = 100;
                }
                let that = this;
                if (timer) {
                    clearTimeout(timer);
                }
                timer = setTimeout(function() {
                that.collectiveSave(); //一秒后才执行的请求
                // 2233
                // that.handleControlLamp()
                    timer = undefined;
                }, 1000);
            }
        },
        //亮度减
        decrease3() {
            if (
                this.lamplightState == '关' ||
                this.lamplightState == '' ||
                this.lamplightState == undefined
            ) {
                this.$message.warning('请打开灯光开关');
            } else if (this.lamplightState == '开') {
                this.percentage3 -= 10;
                console.log(this.percentage3,5555);
                if (this.percentage3 <= 0) {
                    this.percentage3 = 1;
                }
                let that = this;
                if (timer) {
                    clearTimeout(timer);
                }
                timer = setTimeout(function() {
                that.collectiveSave();
                    // that.handleControlLamp()
                    timer = undefined;
                }, 1000);
            }
        },
        //色温加
        increase4() {
            if (
                this.lamplightState == '关' ||
                this.lamplightState == '' ||
                this.lamplightState == undefined
            ) {
                this.$message.warning('请打开灯光开关');
            } else if (this.lamplightState == '开') {
                this.percentage4 += 10;
                if (this.percentage4 > 100) {
                    this.percentage4 = 100;
                }
                let that = this;
                if (timer) {
                    clearTimeout(timer);
                }
                timer = setTimeout(function() {
                // that.handleControlLamp()
                that.collectiveSave();
                    timer = undefined;
                }, 1000);
            }
        },
        //色温减
        decrease4() {
            if (
                this.lamplightState == '关' ||
                this.lamplightState == '' ||
                this.lamplightState == undefined
            ) {
                this.$message.warning('请打开灯光开关');
            } else if (this.lamplightState == '开') {
                this.percentage4 -= 10;
                if (this.percentage4 <= 0) {
                    this.percentage4 = 1;
                }
                let that = this;
                if (timer) {
                    clearTimeout(timer);
                }
                timer = setTimeout(function() {
                // that.handleControlLamp()
                that.collectiveSave();
                    timer = undefined;
                }, 1000);
            }
        },
        increase() {
            if (
                this.conditionerState == '关' ||
                this.conditionerState == '' ||
                this.conditionerState == undefined
            ) {
                this.$message.warning('请打开空调开关');
            } else if (this.conditionerState == '开') {
                this.percentage += 33.333;
                if (this.percentage > 99.999) {
                    this.percentage = 99.999;
                }
                let that = this;
                if (timer) {
                    clearTimeout(timer);
                }
                timer = setTimeout(function() {
                that.collectiveSave(); //一秒后才执行的请求
                // that.handleAirConditioner()
                    timer = undefined;
                }, 1000);
            }
        },
        decrease() {
            if (
                this.conditionerState == '关' ||
                this.conditionerState == '' ||
                this.conditionerState == undefined
            ) {
                this.$message.warning('请打开空调开关');
            } else if (this.conditionerState == '开') {
                this.percentage -= 33.333;
                if (this.percentage < 0) {
                    this.percentage = 0;
                }
                let that = this;
                if (timer) {
                    clearTimeout(timer);
                }
                timer = setTimeout(function() {
                that.collectiveSave(); //一秒后才执行的请求
                // that.handleAirConditioner()
                    timer = undefined;
                }, 1000);
            }
        },
        //事件防抖--温度加
        increase2: function() {
            if (
                this.conditionerState == '关' ||
                this.conditionerState == '' ||
                this.conditionerState == undefined
            ) {
                this.$message.warning('请打开空调开关');
            } else if (this.conditionerState == '开') {
                this.percentage2 += 7.1;
                if (this.percentage2 > 99.4) {
                    this.percentage2 = 99.4;
                }
                let that = this;
                if (timer) {
                    clearTimeout(timer);
                }
                timer = setTimeout(function() {
                that.collectiveSave();
                    // that.handleAirConditioner()
                    timer = undefined;
                }, 1000);
            }
        },
        //温度减
        decrease2() {
            if (
                this.conditionerState == '关' ||
                this.conditionerState == '' ||
                this.conditionerState == undefined
            ) {
                this.$message.warning('请打开空调开关');
            } else if (this.conditionerState == '开') {
                this.percentage2 -= 7.1;
                if (this.percentage2 < 0) {
                    this.percentage2 = 0;
                }
                let that = this;
                if (timer) {
                    clearTimeout(timer);
                }
                timer = setTimeout(function() {
                that.collectiveSave();
                // that.handleAirConditioner()
                    timer = undefined;
                }, 1000);
            }
        },
        pointRefrigeration() {
            if (
                this.conditionerState == '关' ||
                this.conditionerState == '' ||
                this.conditionerState == undefined
            ) {
                this.$message.warning('请打开空调开关');
            } else if (this.conditionerState == '开') {
                this.defaultState();
                this.$refs.pointRefrigeration.style.backgroundColor = '#53b552';
                this.collective.devAirPattern = '制冷';
                this.collectiveSave();
                // this.handleAirConditioner()
            }
        },
        pointHeating() {
            if (
                this.conditionerState == '关' ||
                this.conditionerState == '' ||
                this.conditionerState == undefined
            ) {
                this.$message.warning('请打开空调开关');
            } else if (this.conditionerState == '开') {
                this.defaultState();
                this.$refs.pointHeating.style.backgroundColor = '#53b552';
                this.collective.devAirPattern = '制热';
                this.collectiveSave();
                // this.handleAirConditioner()
            }
        },
        pointAutomatic() {
            if (
                this.conditionerState == '关' ||
                this.conditionerState == '' ||
                this.conditionerState == undefined
            ) {
                this.$message.warning('请打开空调开关');
            } else if (this.conditionerState == '开') {
                this.defaultState();
                this.$refs.pointAutomatic.style.backgroundColor = '#53b552';
                this.collective.devAirPattern = '自动';
                this.collectiveSave();
                // this.handleAirConditioner()
            }
        },
        pointArefaction() {
            if (
                this.conditionerState == '关' ||
                this.conditionerState == '' ||
                this.conditionerState == undefined
            ) {
                this.$message.warning('请打开空调开关');
            } else if (this.conditionerState == '开') {
                this.defaultState();
                this.$refs.pointArefaction.style.backgroundColor = '#53b552';
                this.collective.devAirPattern = '除湿';
                this.collectiveSave();
                // this.handleAirConditioner()
            }
        },
        pointAeration() {
            if (
                this.conditionerState == '关' ||
                this.conditionerState == '' ||
                this.conditionerState == undefined
            ) {
                this.$message.warning('请打开空调开关');
            } else if (this.conditionerState == '开') {
                this.defaultState();
                this.$refs.pointAeration.style.backgroundColor = '#53b552';
                this.collective.devAirPattern = '送风';
                this.collectiveSave();
                // this.handleAirConditioner()
            }
        },
        //集控空调状态
        defaultState() {
            this.$refs.pointRefrigeration.style.backgroundColor = '#9e9f9f';
            this.$refs.pointHeating.style.backgroundColor = '#9e9f9f';
            this.$refs.pointAutomatic.style.backgroundColor = '#9e9f9f';
            this.$refs.pointArefaction.style.backgroundColor = '#9e9f9f';
            this.$refs.pointAeration.style.backgroundColor = '#9e9f9f';
        },
        //风速进度条
        unit(percentage) {
            if (percentage == 99.999) {
                this.collective.devAirWindSpeed = '高';
                return percentage == 99.999 ? '高' : `${percentage}%`;
            } else if (percentage == 66.666) {
                this.collective.devAirWindSpeed = '中';
                return percentage == 66.666 ? '中' : `${percentage}%`;
            } else if (percentage == 33.333) {
                this.collective.devAirWindSpeed = '低';
                return percentage == 33.333 ? '低' : `${percentage}%`;
            } else if (percentage == 0) {
                this.collective.devAirWindSpeed = '自动';
                return percentage == 0 ? '自动' : `${percentage}%`;
            }
        },
        //温度进度条
        unit2(percentage2) {
            let percentage2Tfix = percentage2.toFixed(1);
            if (percentage2Tfix == 99.4) {
                this.collective.devAirTemperature = 30;
                return (percentage2 = '30℃');
            } else if (percentage2Tfix == 92.3) {
                this.collective.devAirTemperature = 29;
                return (percentage2 = '29℃');
            } else if (percentage2Tfix == 85.2) {
                this.collective.devAirTemperature = 28;
                return (percentage2 = '28℃');
            } else if (percentage2Tfix == 78.1) {
                this.collective.devAirTemperature = 27;
                return (percentage2 = '27℃');
            } else if (percentage2Tfix == 71.0) {
                this.collective.devAirTemperature = 26;
                return (percentage2 = '26℃');
            } else if (percentage2Tfix == 63.9) {
                this.collective.devAirTemperature = 25;
                return (percentage2 = '25℃');
            } else if (percentage2Tfix == 56.8) {
                this.collective.devAirTemperature = 24;
                return (percentage2 = '24℃');
            } else if (percentage2Tfix == 49.7) {
                this.collective.devAirTemperature = 23;
                return (percentage2 = '23℃');
            } else if (percentage2Tfix == 42.6) {
                this.collective.devAirTemperature = 22;
                return (percentage2 = '22℃');
            } else if (percentage2Tfix == 35.5) {
                this.collective.devAirTemperature = 21;
                return (percentage2 = '21℃');
            } else if (percentage2Tfix == 28.4) {
                this.collective.devAirTemperature = 20;
                return (percentage2 = '20℃');
            } else if (percentage2Tfix == 21.3) {
                this.collective.devAirTemperature = 19;
                return (percentage2 = '19℃');
            } else if (percentage2Tfix == 14.2) {
                this.collective.devAirTemperature = 18;
                return (percentage2 = '18℃');
            } else if (percentage2Tfix == 7.1) {
                this.collective.devAirTemperature = 17;
                return (percentage2 = '17℃');
            } else if (percentage2Tfix == 0) {
                this.collective.devAirTemperature = 16;
                return (percentage2 = '16℃');
            }
        },
        //位置设备保存
        locationDialogSave() {
            this.intersect(this.collectiveList, this.selectLocation);
            this.locationDialog = false;
        },
        getAllSceneType() { // 获取所有情景类型
            this.control.loading = true
            this.pageShow = false
            this.$axios({
                method: 'get',
                url: '/common2/scene/list',
                params: {
                    hsId: this.control.hsId,
                    isByUser: false
                }
            }).then(res => {
                for (let i of res.data.result) {
                    i.buttonShow = false
                    i.oldSpdDescribe = i.spdDescribe
                }
                if (res.data.result <= 2) {
                    console.log(res.data.result)
                    this.pageShow = true
                }
                this.sceneTypeList = res.data.result
            }).catch(err => {
                this.isError(err, this)
            }).finally(() => {
                this.control.loading = false
            })
        },
        panelSwitch(flag) {
            this.pageShow = flag
        },
        // 情景联动
        clickSceneType(row) {
            let a = this.$message({
                message: '正在执行' + row.jscName + '中...',
                duration: 0,
                type: 'warning',
            });
            sceneModeControl({jsId: row.jsId})
            .then((res) => {
                let time = setInterval(() => {
                    a.close();
                    clearInterval(time);
                }, 3000);
                a.message = '联动成功';
                a.type = 'success';
                console.log(res);
                this.$refs['sceneType'+row.jsId][0].style.backgroundImage =
                'linear-gradient(to right, #6ac7ea , #6ac7ea)';
            })
            .catch((err) => {
                a.close();
                this.isError(err, this);
            });
        },
        // 获取房间下设备
        getVisualization() {
            this.$axios({
                method: 'get',
                url: '/common2/devices/listByHsId',
                params: {
                    hsId: this.control.hsId
                }
            }).then(res => {
                if (res.data.result.length === 0) {
                    this.$message.warning('该房间没有设备')
                }
                console.log(res)
                this.form.dataList = res.data.result
            }).catch(err => {
                this.isError(err,this)
            })
        },
        // 操作设备
        caoZuoClick(row) {
            this.$refs.equipmentTable.rowClick(row)
            this.$refs.equipmentTable.caoZuoClick(row)
        }
    }
}
</script>

<style lang="scss">
.tabs::-webkit-scrollbar{
    display: none;
}
</style>
<style lang="scss" scoped>
.visualization-setting__i{
    cursor: pointer;
    color: #cccccc;
    .fz-24{
        font-size: 20px;
    }
}
.list{
    width: 100%;
    padding-top: 5px;
    .tabs{
        width: 76px;
        float:left;
        overflow: hidden;
        overflow: scroll;
        >>> .el-tabs__item{
            padding: 0 10px;
            color: #606266;
            font-weight: 600;
        }
        >>> .el-tabs__item.is-active {
            color: #409EFF;
        }
    }
    .cardList{
        overflow: scroll;
        .card{
            width: 120px;
            height: 120px;
            margin: 4px;
            float: left;
            position: relative;
            .button{
                font-size: 14px;
                font-weight: 600;
                position: absolute;
                right: 0;
                top: 0;
            }
            .text{
                width: 120px;
                height: 120px;
                cursor:pointer;
                .room_number{
                    width: 70px;
                    margin: 5px 0 0 5px;
                    font-size: 18px;
                    overflow: hidden;
                    position: absolute;
                    left: 0;
                    top: 0;
                }
                .describe{
                    margin: 0 0 5px 5px;
                    font-size: 14px;
                    color: #EBEEF5;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                }
            }
        }
    }
}

/*设备集控样式*/
// .equipmentWith /deep/ .el-dialog__header,
.equipmentWith /deep/ .el-dialog__body {
  height: 600px;
  overflow:auto
//   background-color: #cccccc;
}
.topLeft {
  background-color: #E4E7ED;
  width: 80%;
  margin: 0px auto;
  border-radius: 15px;
  text-align: center;
  height: 240px;
  p {
    font-size: 20px;
    
    text-align: center;
    margin-bottom: 15px;
    padding-top: 20px;
  }
  .switch {
    height: 8px;
    width: 30%;
    background-color: #717071;
    border-radius: 15px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .controlButton {
    width: 80%;
    margin-bottom: 15px;
    margin-left: 0;
    font-size: 20px;
    color: #ffffff;
    background-color: #ccc;
    border-color: #ccc;
    font-weight: 700;
  }
}
.topRight {
    background-color: #E4E7ED;
    width: 80%;
    margin: 0px auto;
    border-radius: 15px;
    text-align: center;
    height: 90px;
    cursor: pointer;
    p {
        font-size: 20px;
        text-align: center;
        margin-bottom: 15px;
        padding-top: 20px;
    }
    .switch {
        height: 8px;
        width: 30%;
        background-color: #717071;
        border-radius: 15px;
        margin: 0 auto;
        margin-bottom: 20px;
    }
}
.el-icon-caret-bottom {
    font-size: 18px;
    text-align: center;
    width: 100%;
    cursor: pointer;
}
.el-icon-caret-top {
    font-size: 18px;
    text-align: center;
    width: 100%;
    cursor: pointer;
    margin-top: 10px;
}
/*  进度条样式*/
.progressBar {
    color: #000;
  width: 200px;
  margin: 20px auto 0;
}
/deep/ .el-progress-bar__outer {
  background-color: #E4E7ED;
}
.progressButton {
  margin: 13px 0;
  span {
    color: #ffffff;
    font-size: 20px;
    padding: 0 10px;
  }
  .el-button {
    height: 53px;
    width: 55px;
    background-color: rgb(151, 151, 151);
    border-color: rgb(151, 151, 151);
  }
  /deep/ .el-icon-plus,
  /deep/ .el-icon-minus {
    font-size: 20px;
  }
  /deep/ .el-icon-plus:before,
  /deep/ .el-icon-minus:before {
    color: #ffffff;
  }
}
/deep/ .el-progress-bar__inner {
  background-image: linear-gradient(to right, #51b44d, #6ac7ea);
}
/deep/ .el-progress-bar__innerText {
  font-size: 18px;
}
/*  空调模式样式*/
.equipmentWith {
  .model {
    width: 90%;
    margin: 0 auto;
    .modelChild {
      width: 20%;
      float: left;
      text-align: center;
      .dot {
        height: 10px;
        width: 10px;
        background-color: #9e9f9f;
        border-radius: 50%;
        margin: 5px auto 0;
      }
      p {
        font-size: 16px;
        margin: 5px 0;
      }
      .bigDot {
        height: 52px;
        width: 50px;
        background-color: #9e9f9f;
        border-radius: 50%;
        margin: 10px auto;
        cursor: pointer;
      }
    }
  }
  /deep/.el-main {
      padding: 0;
  }
}
/* 可以设置不同的进入和离开动画 */
/* 设置持续时间和动画函数 */
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s
}
.fade-enter, .fade-leave-to /* .fade-leave-active, 2.1.8 版本以下 */ {
    opacity: 0
}
.single_control {
    margin-bottom: 20px;
    user-select: none;
    -moz-user-select: none;
    .topLeft {
        cursor: pointer;
        height: 120px;
        background-color: #E4E7ED;
        width: 80%;
        border-radius: 15px;
        text-align: center;
        padding-top: 10px;
        position: relative;
        div {
            font-size: 17px;
            text-align: center;
            position: absolute;
            bottom: 5px;
            width:100%;
        }
        img {
            height: 75px;
            width: 75px;
        }
    }
    .topLeft:hover {
        background-color: #cdcfd4;
    }
}
</style>
<template>
   <el-dialog
   v-dialogDrag
  :title="title"
  :visible.sync="dialogVisible"
  append-to-body
  width="1000px"
  :before-close="handleClose">
    <div class="center">
        <!--  -->
        <el-transfer
        filterable
         v-model="value" 
         :data="leftData"
         :left-default-checked="[]"
         :right-default-checked="[]"
         :titles="['可管理设备', '已管理设备']"
         >
         <span slot-scope="{ option }">{{ option.label }}</span>
         </el-transfer>
    </div>
  <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="changeSetting">提 交</el-button>
  </span>
</el-dialog>
</template>

<script>
// v-dialogDrag 可拖曳
import { allSettingData,settingDevice } from '../../../api/visualization'
export default {
    props:{
        dialogVisible:Boolean,
        // settingId:Number,
    },
    data(){
        return {
            title:'',
            // data左边数据
             leftData: [],
            //  value 右边数据
             value: [],
             deviceType:null,
             hsId: ''
        }
    },
    methods:{
        handleClose(){
            this.$emit('handleSettingDialogClose')
        },
        getSettingInfo(flag,hsId){
            this.deviceType = flag
            switch(flag){
                case 1:
                    this.title = '开关控制'
                    break
                case 2:
                    this.title = '灯光控制'
                    break
                case 3:
                    this.title = '窗帘控制'
                    break
                case 4:
                    this.title = '空调控制'
                    break
                default:
                    this.title = '出错啦'
            }
            // hsId:this.settingId,jcrType:flag
            // 获取管理设备
            // hsId:this.settingId
            if(flag === 4){
                return 
            }
            this.hsId=hsId;
            const params = {jcrType:flag,hsId }
            allSettingData(params).then(res => {
                // 可管理设备
                const res1 = res[0] || []
                // 已管理设备
                const res2 = res[1] || []
                const data = res1.data.result.concat(res2.data.result)
                console.log(data);
                this.leftData = data.map(item => {
                    return {
                        key:item.jcrDevId,
                        label:item.devNickname
                    }
                })
                this.value = res2.data.result.map(item => item.jcrDevId) || []
           })
        },
        changeSetting(){
           const data = {
               hsId:this.hsId,
               jcrType:this.deviceType,
               deviceIds:this.value
           }
           console.log(data);
        //    return
           settingDevice(data).then(res => {
               console.log(res);
              if(res.data.status === 200){
                  this.getSettingInfo(this.deviceType,this.hsId)
                  this.$message.success('修改成功')
              }
           })
        }
    }
}
</script>

<style scoped>
.center{
    display: flex;
    justify-content: center;
    align-items: center;
}
.center  >>> .el-transfer-panel {
     width:350px;
}
</style>